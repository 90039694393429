<template>
  <div v-if="selectedCategoria">
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
    >
      <b-card>
        <categoriasForm
          categoriaSubmit="Editar Categoría"
          :categoria="selectedCategoria"
          @processCategoria="editarCategoria"
        />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import { BCard, BOverlay } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const categoriasForm = () => import('@/layouts/components/Categorias/CategoriasForm.vue')

export default {
  components: {
    BCard,
    categoriasForm,
    BOverlay,
  },
  data() {
    return {
      spinner: false,
    }
  },
  computed: {
    ...mapState('categorias', ['selectedCategoria']),
  },
  methods: {
    ...mapActions({ updateCategoria: 'categorias/updateCategorias' }),
    editarCategoria(categoria) {
      this.spinner = true
      this.updateCategoria(categoria).then(() => {
        const errorCategorias = store.state.categorias
        const errorMessage = errorCategorias.errorMessage.errors
        if (!errorCategorias.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Categoría editada con éxito 👍',
              text: `La categoría "${categoria.nombre}" fue editada con éxito!`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            timeout: 4000,
          })
          this.$router.replace({
            name: 'categorias',
          })
        } else if (errorMessage.nombre) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.nombre[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else if (errorCategorias.error) {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
